import {gsap} from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

gsap.utils.toArray(".society").forEach(function (el) {
    gsap.from(el, {
        scrollTrigger: {
            trigger: el,
            start: "top 110%",
            once: true,
            // end: "top 90%",
            // scrub: true
            // onEnter: () => {
            //
            // }
        },
        duration: 0.75,
        y: 100, // Start at -300px on the y-axis
        opacity: 0, // Start with an opacity of 0
        ease: "none"
    });
});
gsap.from('#image-header', {
    scrollTrigger: {
        trigger: 'html',
        start: "top 100%",
        once: true,
        // end: "top 90%",
        // scrub: true
        // onEnter: () => {
        //
        // }
    },
    duration: 0.1,
    opacity: 0, // Start with an opacity of 0
    ease: "none"
});
gsap.from('#logo-header', {
    scrollTrigger: {
        trigger: 'html',
        start: "top 100%",
        once: true,
        // end: "top 90%",
        // scrub: true
        // onEnter: () => {
        //
        // }
    },
    duration: 0.5,
    delay: 0.1,
    opacity: 0, // Start with an opacity of 0
    ease: "none"
});
gsap.from('#intro-header', {
    scrollTrigger: {
        trigger: 'html',
        start: "top 100%",
        once: true,
        // end: "top 90%",
        // scrub: true
        // onEnter: () => {
        //
        // }
    },
    duration: 0.25,
    delay: 0.2,
    y: 50, // Start at -300px on the y-axis
    opacity: 0, // Start with an opacity of 0
    ease: "none"
});
gsap.from('#menu-header', {
    scrollTrigger: {
        trigger: 'html',
        start: "top 110%",
        once: true,
        // end: "top 90%",
        // scrub: true
        // onEnter: () => {
        //
        // }
    },
    duration: 0.25,
    delay: 0.3,
    y: 50, // Start at -300px on the y-axis
    opacity: 0, // Start with an opacity of 0
    ease: "none"
});
const decalage = 150;
if (window.matchMedia("(min-width: 1024px)").matches) {
    gsap.from('#image-section-1', {
        scrollTrigger: {
            trigger: '#image-section-1',
            start: "top 80%",
            once: true,
        },
        duration: 0.5,
        y: decalage, // Start at -300px on the y-axis
        opacity: 0, // Start with an opacity of 0
        ease: "none"
    });
    gsap.from('#image-section-3', {
        scrollTrigger: {
            trigger: '#image-section-3',
            start: "top 80%",
            once: true,
        },
        duration: 0.5,
        y: decalage, // Start at -300px on the y-axis
        opacity: 0, // Start with an opacity of 0
        ease: "none"
    });
    gsap.from('#text-section-1', {
        scrollTrigger: {
            trigger: '#text-section-1',
            start: "top 80%",
            once: true,
        },
        duration: 0.5,
        x: decalage, // Start at -300px on the y-axis
        opacity: 0, // Start with an opacity of 0
        ease: "none"
    });
    gsap.from('#text-section-2', {
        scrollTrigger: {
            trigger: '#text-section-2',
            start: "top 80%",
            once: true,
        },
        duration: 0.5,
        x: decalage, // Start at -300px on the y-axis
        opacity: 0, // Start with an opacity of 0
        ease: "none"
    });
    gsap.from('#title-section-2', {
        scrollTrigger: {
            trigger: '#title-section-2',
            start: "top 80%",
            once: true,
        },
        duration: 0.5,
        opacity: 0, // Start with an opacity of 0
        ease: "none"
    });
    gsap.from('#text-section-3', {
        scrollTrigger: {
            trigger: '#text-section-3',
            start: "top 80%",
            once: true,
        },
        duration: 0.5,
        x: -decalage, // Start at -300px on the y-axis
        opacity: 0, // Start with an opacity of 0
        ease: "none"
    });
}



const headerContainer = document.querySelector('.header-container');
const headerStickyContainer = document.querySelector('.header-sticky-container');
let isMenuShown = false; // Flag to toggle the menu
window.addEventListener('scroll', () => {
    const bottomPosition = headerContainer.offsetTop + headerContainer.offsetHeight;

    if (window.scrollY >= bottomPosition && !isMenuShown) {
        isMenuShown = true;
        headerStickyContainer.classList.add('show');
    } else if (window.scrollY < bottomPosition && isMenuShown) {
        headerStickyContainer.classList.remove('show');
        isMenuShown = false;
    }
});